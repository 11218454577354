/* Базовые стили */
body, html, #root, .App {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #000000;
  color: #ffffff;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 0; /* Убираем padding */
  box-sizing: border-box;
  position: relative;
}

h1 {
  color: #ffffff;
  font-size: 24px;
  margin: 0;
}

/* Основной контент */
.App-main {
  flex: 1;
  overflow: hidden;
  padding: 0 20px;
  padding-top: 0; /* Убираем верхний отступ */
  height: calc(100vh - 80px); /* Учитываем только нижнюю навигацию */
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
}

/* Кнопка подключения кошелька */
.connect-wallet-button {
  margin: 0;
  padding: 0 30px;
}

.connect-wallet-button button,
.connect-wallet-button .go2267139606 {
  display: flex;
  align-items: center;
  padding: 0 16px !important;
  border-radius: 12px !important;
  font-size: 14px !important;
  white-space: nowrap !important;
  margin-right: 0 !important;
  background-color: #121214 !important;
  color: #fff !important;
  transition: all 0.3s ease !important;
  border: none !important;
  outline: none !important;
}

/* Добавляем селекторы, основанные на классе .connected */
.connect-wallet-button:not(.connected) button,
.connect-wallet-button:not(.connected) .go2267139606 {
  animation: pulse 2s infinite !important;
}

.connect-wallet-button.connected button,
.connect-wallet-button.connected .go2267139606 {
  background-color: #121214 !important;
  animation: none !important;
}

.connect-wallet-button button span,
.connect-wallet-button .go2267139606 .go1651228014 {
  color: #fff !important;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

.go1651228014 {
  color: #fff !important;
}

/* Кабинет */
.cabinet {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.cabinet-content {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 0;
  max-width: 560px;
  margin: 0 auto;
  width: calc(100% - 30px);
  min-width: 300px;
}

.wallet-section, .nft-carousel {
  margin-bottom: 10px;
  width: 100%;
  box-sizing: border-box;
}

.cabinet-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: transparent;
  padding: 10px 15px;
  max-width: 560px;
  margin: 0 auto;
  width: 100%;
}

.wallet-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  background-color: #121214;
  border-radius: 12px 12px 0 0;
  margin-bottom: 1px;
}


.nft-carousel-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  background-color: #121214;
  border-radius: 12px 12px 0 0;
  margin-bottom: 1px;
}

.nft-carousel:not(.collapsed) .nft-carousel-header {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.nft-carousel:not(.collapsed) .nft-content {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.nft-carousel.collapsed .nft-carousel-header {
  border-radius: 12px;
}

.cabinet-controls {
  display: flex;
  gap: 15px;
}

.refresh-button, .collapse-button {
  background-color: transparent;
  border: none;
  color: #ffffff;
  cursor: pointer;
  padding: 5px;
  font-size: 16px;
}

.wallet-title, .nft-title {
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  margin: 0;
}

.wallet-content, .nft-content {
  transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
  max-height: 1000px;
  opacity: 1;
  overflow: hidden;
}

.wallet-content.collapsed, .nft-content.collapsed {
  max-height: 0;
  opacity: 0;
}

.refresh-button {
  background-color: #1a1a1a;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.refresh-button svg {
  transition: transform 0.3s ease;
}

.refresh-button:hover svg {
  transform: rotate(180deg);
}

.refresh-button.loading svg {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.token-list {
  border-radius: 12px;
  overflow: hidden;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.token-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  background-color: #121214;
  border-bottom: 1px solid #000;
}

.token-item:last-child {
  border-bottom: none;
}

.token-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.token-info {
  display: flex;
  align-items: center;
}

.token-icon, .token-icon-placeholder {
  width: 32px;
  height: 32px;
  min-width: 32px;
  margin-right: 6px;
  border-radius: 50%;
}

.token-details {
  display: flex;
  flex-direction: column;
}

.token-symbol {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
}

.token-balance-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.token-balance {
  font-size: 16px;
  font-weight: bold;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  color: #fff;
}

.token-balance.loaded {
  opacity: 1;
}

.token-balance.updating {
  opacity: 0.5;
}

.token-change {
  font-size: 14px;
  color: #4CAF50;
}

.token-change.negative {
  color: #F44336;
}

/* Кнопка "Проверить NFT" */
.nft-check {
  position: fixed;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 600px;
  padding: 0 20px;
  box-sizing: border-box;
  z-index: 1000;
  }

  .check-nft-button {
    width: 100%;
  max-width: 560px;
  margin: 0 auto;
    padding: 15px 20px;
    background-color: #121214;
    color: #fff;
    border: none;
    border-radius: 12px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-align: center;
    text-decoration: none;
    display: block;
    box-sizing: border-box;
    height: 52px; /* Высота, соответствующая заголовкам */
  }

.check-nft-button:hover {
  background-color: #2c2c2c;
}

.check-nft-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  background-color: #444; /* Добавим это для наглядности */
}

.check-nft-button:not(:disabled) {
  background-color: #121214;
}

.check-nft-button.success {
  background-color: #4CAF50;
}

.check-nft-button.error {
  background-color: #0098EA;
}

.nft-status {
  text-align: center;
  margin-top: 10px;
}

/* Навигация */
.App-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: #000;
  padding: 10px 0;
  display: flex;
  justify-content: space-around;
}

.nav-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: none;
  border: none;
  color: #808080;
  font-size: 12px;
  cursor: pointer;
}

.nav-button.active {
  color: white;
}

.nav-button svg {
  font-size: 24px;
  margin-bottom: 5px;
}

/* NFT карусель */
.nft-carousel-content {
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}

.nft-images {
  display: flex;
  gap: 10px;
  overflow-x: auto;
  padding-bottom: 10px;
  background-color: transparent;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
  scrollbar-color: #4a4a4a #121214;
  scroll-behavior: auto; /* Изменяем на auto для мгновенной реакции */
}

.nft-images::-webkit-scrollbar {
  height: 8px;
}

.nft-images::-webkit-scrollbar-track {
  background: #121214;
  border-radius: 4px;
}

.nft-images::-webkit-scrollbar-thumb {
  background-color: #4a4a4a;
  border-radius: 4px;
  border: 2px solid #121214;
}

.nft-image {
  border-radius: 12px;
  width: 100px;
  height: 100px;
  object-fit: cover;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  pointer-events: none;
  transition: transform 0.3s ease;
}

.nft-image:hover {
  transform: scale(1.05);
}

/* Медиа-запросы */
@media (max-width: 650px) {
  

  .App-main {
    width: 100%;
    padding: 0;
  }

 
  
}

@media (max-width: 480px) {
  .App {
    padding: 0px;
  }

  h1 {
    font-size: 20px;
  }

  .cabinet {
    width: 100%;
  }

  .token-item {
    padding: 8px;
  }
}

@media (max-width: 360px) {
  .token-info {
    font-size: 12px;
  }

  .token-balance {
    font-size: 12px;
  }
}

.wallet-section.collapsed .cabinet-header,
.nft-carousel.collapsed .nft-carousel-header {
  border-radius: 12px;
}


.nft-title {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}

.nft-content {
  padding-top: 10px;
}

/* Обновленные классы без автоматических отступов */
.cabinet-content,
.wallet-section,
.nft-carousel,
.nft-check {
  max-width: 560px;
  margin-left: auto;
  margin-right: auto;
}

.cabinet-header,
.nft-carousel-header,
.check-nft-button {
  max-width: 560px;
  margin-left: auto;
  margin-right: auto;
}

.nav-button {
  color: #808080;
}

.nav-button.active {
  color: white;
}

* {
  -webkit-tap-highlight-color: transparent;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
    transform: scale(1);
  }
  50% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    transform: scale(1.05);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    transform: scale(1);
  }
}

.pulse-animation {
  animation: pulse 2s infinite;
}

.page {
  width: 100%;
  height: 100%;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  opacity: 0;
  visibility: hidden;
}

.visible {
  opacity: 1;
  visibility: visible;
}

.hidden {
  opacity: 0;
  visibility: hidden;
}


.game-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding-bottom: 10px;
  box-sizing: border-box;
}

.game-text {
  color: white;
  font-size: 24px;
  font-weight: bold;
}

@media (max-width: 768px) {
  .game-text {
    font-size: 20px;
  }
}

.page.visible {
  opacity: 1;
  visibility: visible;
}

.page.transitioning {
  opacity: 0;
}

.connect-wallet-button {
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.connect-wallet-button.hidden {
  opacity: 0;
  visibility: hidden;
}

.connect-wallet-button.visible {
  opacity: 1;
  visibility: visible;
}

.App-header.transitioning .connect-wallet-button {
  opacity: 0;
  visibility: hidden;
}

.cabinet-content {
  display: flex;
  flex-direction: column;
}









